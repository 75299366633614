.view-account-container {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
}

.account-search-container {
    display: flex;
}

@media screen and (max-width: 768px) {
    .account-search-container {
        display: block;
    }

    .random-account-button {
        width: 100%;
    }
}

