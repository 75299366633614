@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,100;1,300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    font-family: 'Roboto';
}

nav {
    display: -webkit-flex;
    display: flex;
    box-shadow: rgb(0 0 0 / 15%) 0px 5px 20px;
    height: 50px;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 16px;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.title {
    font-weight: bold;
}

.test-net-warning {
    padding: 16px;
    margin: 16px;
    background-color: red;
    border-radius: 10px;
    color: white;
}

.nav-link {
    color: inherit;
    text-decoration: inherit;
}

.metamask-button {
    cursor: pointer;
    color: #FF6600; 
}

.form-container {
    width: 100%;
    max-width: 500px;
}

.form {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.label-container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.account-input-container {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    position: relative;
}

.account-error-text {
    position: absolute;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    top: 2px;
    font-size: 12px;
    left: 10px;
    color: red;
    text-align: center;
}

.label {
    font-weight: bold;
}

.wallet-input {
    width: 100%;
    border: 2px solid #eee;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
    border-radius: 10px;
    padding: 10px;
}

.wallet-input:focus {
    outline: none !important;
    border-color: #000;
}


.button {
    border: 2px solid #eee;
    border-radius: 10px;
    background-color: #4169E1;
    color: white;
    padding: 10px;
    cursor: pointer;
}

.button:focus {
    outline: none !important;
}

.button:active {
    background-color: #4133E1;
}

.view-account-container {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
}

.account-search-container {
    display: -webkit-flex;
    display: flex;
}

@media screen and (max-width: 768px) {
    .account-search-container {
        display: block;
    }

    .random-account-button {
        width: 100%;
    }
}


.image-grid {
    display: inline-grid;
    grid-template-columns: repeat(5, 1fr);
}

@media screen and (max-width: 768px) {
    .image-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

.token-image-container {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.token-image-container:hover .hover-container {
    visibility: visible;
}

.hover-container {
    cursor: pointer;
    visibility: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

.hover-text {
    color: white;
}

.token-image {
    width: 100%;
}

.piece-header {
    padding-left: 16px;
    text-decoration: underline;
}

.active-piece-container {
    position: fixed;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.active-piece-card {
    position: relative;
    width: 50%;
    background-color: white;
    border: 1px black solid;
    padding: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.iframe-container {
    position: relative;
    width: 50%;
    height: 300px;
}

.exit-button {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .active-piece-card {
        display: block;
        max-height: 100%;
    }

    .active-piece-container {
        -webkit-align-items: normal;
                align-items: normal;
    }

    .info {
        font-size: 1.6vh;
    }

    .active-piece-card {
        width: 90%;
    }

    .iframe-container {
        width: 100%;
    }

    .exit-button {
        position: static;
        text-align: right;
    }
}

.iframe-container:before {
    content: "";
    display: block;
    padding-top: 100%;
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
}

.feature-line {
    display: -webkit-flex;
    display: flex;
}

.feature-name {
    font-weight: bold;
}

.piece-stats-container {
    -webkit-flex: .5 1;
            flex: .5 1;
}

.tooltip {
    position: relative;
    color: #4169E1;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 60%;
}

table {
    border-collapse: collapse;
}

.table-footer {
    border-top: 1px black solid;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.table-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.center-column {
    text-align: center;
}

.missing {
    opacity: .5;
}

.feature-display {
    display: -webkit-flex;
    display: flex;
}

.feature-display-title {
    font-weight: bold;
}

.project-details-container {
    -webkit-flex: .5 1;
            flex: .5 1;
}

.bold-text {
    font-weight: bold;
}

.printed-pieces-ul {
    margin: 0;
}

.bold-text {
    font-weight: bold;
}

.clickable {
    cursor: pointer;
}

.project-information-container {
    display: -webkit-flex;
    display: flex;
    padding: 0 16px;
}

@media screen and (max-width: 768px) {
    .project-information-container {
        display: block;
    }
}


.no-project {
    padding: 10px;
    text-align: center;
}

.bold-error {
    font-weight: bold;
    font-size: 32px;
}

.container {
    padding: 0 16px;
}

.project-preview-container {
    display: -webkit-flex;
    display: flex;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.preview-image {
    width: 100px;
}

.project-title {
    margin: 0px;
}

.info-container {
    padding-left: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

