@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,100;1,300&display=swap');

body {
    font-family: 'Roboto';
}

nav {
    display: flex;
    box-shadow: rgb(0 0 0 / 15%) 0px 5px 20px;
    height: 50px;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
}

.title {
    font-weight: bold;
}

.test-net-warning {
    padding: 16px;
    margin: 16px;
    background-color: red;
    border-radius: 10px;
    color: white;
}

.nav-link {
    color: inherit;
    text-decoration: inherit;
}
