.project-details-container {
    flex: .5;
}

.bold-text {
    font-weight: bold;
}

.printed-pieces-ul {
    margin: 0;
}
