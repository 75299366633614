.piece-stats-container {
    flex: .5;
}

.tooltip {
    position: relative;
    color: #4169E1;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: fit-content;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 60%;
}

table {
    border-collapse: collapse;
}

.table-footer {
    border-top: 1px black solid;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.table-container {
    display: flex;
    justify-content: center;
}

.center-column {
    text-align: center;
}

.missing {
    opacity: .5;
}

.feature-display {
    display: flex;
}

.feature-display-title {
    font-weight: bold;
}
