.active-piece-container {
    position: fixed;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.active-piece-card {
    position: relative;
    width: 50%;
    background-color: white;
    border: 1px black solid;
    padding: 16px;
    display: flex;
    align-items: center;
}

.iframe-container {
    position: relative;
    width: 50%;
    height: 300px;
}

.exit-button {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .active-piece-card {
        display: block;
        max-height: 100%;
    }

    .active-piece-container {
        align-items: normal;
    }

    .info {
        font-size: 1.6vh;
    }

    .active-piece-card {
        width: 90%;
    }

    .iframe-container {
        width: 100%;
    }

    .exit-button {
        position: static;
        text-align: right;
    }
}

.iframe-container:before {
    content: "";
    display: block;
    padding-top: 100%;
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
}
