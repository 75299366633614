.form-container {
    width: 100%;
    max-width: 500px;
}

.form {
    display: flex;
    align-items: center;
}

.label-container {
    width: 100%;
    display: flex;
    align-items: center;
}

.account-input-container {
    flex: 1;
    display: flex;
    position: relative;
}

.account-error-text {
    position: absolute;
    user-select: none;
    top: 2px;
    font-size: 12px;
    left: 10px;
    color: red;
    text-align: center;
}

.label {
    font-weight: bold;
}

.wallet-input {
    width: 100%;
    border: 2px solid #eee;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
    border-radius: 10px;
    padding: 10px;
}

.wallet-input:focus {
    outline: none !important;
    border-color: #000;
}

